export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [4],
		"/(app)/alerts": [~5,[2]],
		"/(app)/analytics": [6,[2]],
		"/(app)/analytics/autobus": [~7,[2]],
		"/(app)/analytics/banner": [~8,[2]],
		"/(app)/analytics/category": [~9,[2]],
		"/(app)/analytics/click": [~10,[2]],
		"/(app)/announcements": [~11,[2]],
		"/(app)/banners": [~12,[2]],
		"/(app)/bus": [13,[2]],
		"/(app)/bus/autolinee": [~14,[2]],
		"/(app)/bus/lines": [~15,[2]],
		"/(app)/bus/stops": [~16,[2]],
		"/(app)/bus/zones": [~17,[2]],
		"/(app)/customize": [~18,[2]],
		"/(app)/dashboard-plus": [~20,[2]],
		"/(app)/dashboard": [~19,[2]],
		"/details/banner/[id=id_or_new]": [~33,[3]],
		"/details/bus-autolinee/[id=id_or_new]": [~34,[3]],
		"/details/bus-line/[id=id_or_new]": [~35,[3]],
		"/details/bus-stop/[id=id_or_new]": [~36,[3]],
		"/details/bus-zone/[id=id_or_new]": [~37,[3]],
		"/details/holiday/[type=holiday_type]/[supplierId]/[id=id_or_new]": [~38,[3]],
		"/details/location/[supplierId=id]/[id=id_or_new]": [~39,[3]],
		"/details/notification/[id=id_or_new]": [~40,[3]],
		"/details/product-category/[supplierId]/[id=id_or_new]": [~41,[3]],
		"/details/supplier-category/[id=id]": [~42,[3]],
		"/details/user/[id=id]": [~43,[3]],
		"/details/[detail=supplier_product_detail]/[supplierId]/[id=id_or_new]": [~32,[3]],
		"/(app)/events": [~21,[2]],
		"/login": [~44],
		"/logout": [~45],
		"/(app)/notifications": [~22,[2]],
		"/(app)/skins": [~23,[2]],
		"/(app)/supplier-categories": [~24,[2]],
		"/supplier-new": [~47],
		"/(app)/suppliers": [~25,[2]],
		"/supplier/[id=id]": [~46],
		"/(app)/to-validates": [26,[2]],
		"/(app)/to-validates/announcements": [~27,[2]],
		"/(app)/to-validates/banners": [~28,[2]],
		"/(app)/to-validates/events": [~29,[2]],
		"/(app)/to-validates/suppliers": [~30,[2]],
		"/(app)/users": [~31,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';